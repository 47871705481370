import React from 'react'
import { useParams } from "react-router-dom";

//import Dpdf from "./Dpdf"
//import Mpdf from "./Mpdf"

function Blogv() {
   /* handleResize = e => {
        const windowSize = window.innerWidth;
        if (windowSize <= 1200) {
            this.setState({
                re: false
            });
        } else {
            this.setState({
                re: true
            });
        }

    };
    constructor(props) {
        super(props);

        if (window.innerWidth <= 1200) {
            this.state = {
                re: false
            };
        } else {
            this.state = {
                re: true
            };
        }
    }
    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }*/
 
       // var { re } = this.state;
    const { id } = useParams();
        return (
            <div>
                <iframe title={id} src={"//docs.google.com/gview?embedded=true&url=https://revannekpah.org/"+ id + ".pdf"} style={{width:"100%",height:"700px"}} frameborder="0"></iframe>
            </div>
        )
    
}

export default Blogv