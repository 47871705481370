import React from "react";
import me from '../img/rev.png';

export default class About extends React.Component {
  
    render() {
     
    return (
       
        <div>
            <div className="bii">
                <img src={me} alt="Rev Ann E. Ekpah" className="m-i" />
                <div className="b-i">
                    <div className="m-b-t">Rev Ann E. Ekpah Biography</div>
                    <div style={{ fontSize: "20px"}}>Reverend Ann E. Ekpah, African-Canadian descent, Associate degree in theology Canadian institute of christian counselling and seminary Toronto 2011-2013., Minister, Christ Evangelical Ministry. Spanishtown, Jamaica  2005 - 2009., Deeper Life school of Evangelism. Jamaica 2006 - 2008.</div>
                    <div className="b-box">
                        <hr></hr>
                       
                    
                        <p>The ministry was founded in Spanishtown parish in Jamaica, No. 1 Buckroad with 5000
members. An outreach is done in Spanishtown hospital, where gifts and hospitable items
are distributed. Sister Esther would pray with people in the hospital and they testify that
they were healed. Matron Campbell would give Pastor. Esther and her team an
opportunity every 12 midday for a brief reflection with patients. Seminars were held and
topics like: reconstruction of broken marriages as well as, seminars for youth with
challenges.</p>
                        <hr></hr>
                        <p>In 2009 June, she migrated to Canada. She focuses mainly on a sound teaching of the
bible and deliverance. Between 2011 - 2013, she went to Canadian Institute of Christian counselling and seminary, and obtained an associate degree in theology. She coordinates
marriages, baby dedications, house dedications and burial ceremonies.</p>
                        <hr></hr>
                        <p>She has two grown boys. Nelson and David.She married when she was 18 years and she is blessed with the boys.</p>
                        <hr></hr>
                    </div>
                </div>
            </div>
        </div>
    );
}
}



