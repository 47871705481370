import React from "react";
import win from "../img/windows.png"
import mac from "../img/mac.png";
import a from "../img/join-meeting-or-sign-in-screen.png";
import b from "../img/join-button.png";
import c from "../img/join-a-meeting.png";
import li from "../img/linux.png";
import d from "../img/enter-meeting-id-or-personal-link-name.png";
import an from "../img/android.png";
import e from "../img/enter-meeting-id-or-personal-link-name (1).png";
import e2 from "../img/enter-meeting-id-or-personal-link-name (2).png";
import we from "../img/web.png";
import f from "../img/join-a-meeting (1).png";
import g from "../img/chrome-open-zoom.png";
import h from "../img/safari-launch-zoom.png";
import i from "../img/firefox-open-link.png";


export default class Joinfriday extends React.Component {
    wi = () => {
        var x = document.getElementById("w");
        var xx = document.getElementById("w-b");
        if (x.style.display === "none") {
            x.style.display = "block";
            xx.setAttribute('d', "M13.889,11.611c-0.17,0.17-0.443,0.17-0.612,0l-3.189-3.187l-3.363,3.36c-0.171,0.171-0.441,0.171-0.612,0c-0.172-0.169-0.172-0.443,0-0.611l3.667-3.669c0.17-0.17,0.445-0.172,0.614,0l3.496,3.493C14.058,11.167,14.061,11.443,13.889,11.611 M18.25,10c0,4.558-3.693,8.25-8.25,8.25c-4.557,0-8.25-3.692-8.25-8.25c0-4.557,3.693-8.25,8.25-8.25C14.557,1.75,18.25,5.443,18.25,10 M17.383,10c0-4.07-3.312-7.382-7.383-7.382S2.618,5.93,2.618,10S5.93,17.381,10,17.381S17.383,14.07,17.383,10");
        } else {
            x.style.display = "none";
            xx.setAttribute('d', "M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10");
        }
    }
    io = () => {
        var x = document.getElementById("i");
        var xx = document.getElementById("i-o");
        if (x.style.display === "none") {
            x.style.display = "block";
            xx.setAttribute('d', "M13.889,11.611c-0.17,0.17-0.443,0.17-0.612,0l-3.189-3.187l-3.363,3.36c-0.171,0.171-0.441,0.171-0.612,0c-0.172-0.169-0.172-0.443,0-0.611l3.667-3.669c0.17-0.17,0.445-0.172,0.614,0l3.496,3.493C14.058,11.167,14.061,11.443,13.889,11.611 M18.25,10c0,4.558-3.693,8.25-8.25,8.25c-4.557,0-8.25-3.692-8.25-8.25c0-4.557,3.693-8.25,8.25-8.25C14.557,1.75,18.25,5.443,18.25,10 M17.383,10c0-4.07-3.312-7.382-7.383-7.382S2.618,5.93,2.618,10S5.93,17.381,10,17.381S17.383,14.07,17.383,10");
        } else {
            x.style.display = "none";
            xx.setAttribute('d', "M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10");
        }
    }

    li = () => {
        var x = document.getElementById("l");
        var xx = document.getElementById("l-i");
        if (x.style.display === "none") {
            x.style.display = "block";
            xx.setAttribute('d', "M13.889,11.611c-0.17,0.17-0.443,0.17-0.612,0l-3.189-3.187l-3.363,3.36c-0.171,0.171-0.441,0.171-0.612,0c-0.172-0.169-0.172-0.443,0-0.611l3.667-3.669c0.17-0.17,0.445-0.172,0.614,0l3.496,3.493C14.058,11.167,14.061,11.443,13.889,11.611 M18.25,10c0,4.558-3.693,8.25-8.25,8.25c-4.557,0-8.25-3.692-8.25-8.25c0-4.557,3.693-8.25,8.25-8.25C14.557,1.75,18.25,5.443,18.25,10 M17.383,10c0-4.07-3.312-7.382-7.383-7.382S2.618,5.93,2.618,10S5.93,17.381,10,17.381S17.383,14.07,17.383,10");
        } else {
            x.style.display = "none";
            xx.setAttribute('d', "M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10");
        }
    }
    br = () => {
        var x = document.getElementById("br");
        var xx = document.getElementById("b-r");
        if (x.style.display === "none") {
            x.style.display = "block";
            xx.setAttribute('d', "M13.889,11.611c-0.17,0.17-0.443,0.17-0.612,0l-3.189-3.187l-3.363,3.36c-0.171,0.171-0.441,0.171-0.612,0c-0.172-0.169-0.172-0.443,0-0.611l3.667-3.669c0.17-0.17,0.445-0.172,0.614,0l3.496,3.493C14.058,11.167,14.061,11.443,13.889,11.611 M18.25,10c0,4.558-3.693,8.25-8.25,8.25c-4.557,0-8.25-3.692-8.25-8.25c0-4.557,3.693-8.25,8.25-8.25C14.557,1.75,18.25,5.443,18.25,10 M17.383,10c0-4.07-3.312-7.382-7.383-7.382S2.618,5.93,2.618,10S5.93,17.381,10,17.381S17.383,14.07,17.383,10");
        } else {
            x.style.display = "none";
            xx.setAttribute('d', "M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10");
        }
    }
    an = () => {
        console.log(a)
        var x = document.getElementById("aa");
        var xx = document.getElementById("a-n");
        if (x.style.display === "none") {
            x.style.display = "block";
            xx.setAttribute('d', "M13.889,11.611c-0.17,0.17-0.443,0.17-0.612,0l-3.189-3.187l-3.363,3.36c-0.171,0.171-0.441,0.171-0.612,0c-0.172-0.169-0.172-0.443,0-0.611l3.667-3.669c0.17-0.17,0.445-0.172,0.614,0l3.496,3.493C14.058,11.167,14.061,11.443,13.889,11.611 M18.25,10c0,4.558-3.693,8.25-8.25,8.25c-4.557,0-8.25-3.692-8.25-8.25c0-4.557,3.693-8.25,8.25-8.25C14.557,1.75,18.25,5.443,18.25,10 M17.383,10c0-4.07-3.312-7.382-7.383-7.382S2.618,5.93,2.618,10S5.93,17.381,10,17.381S17.383,14.07,17.383,10");
        } else {
            x.style.display = "none";
            xx.setAttribute('d', "M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10");
        }
    }
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <div className="m-b-t">How to join the friday - prayer and fasting conference through zoom</div>
                <div className="b-box">
                    <hr></hr>
                    <p><b>To join the zoom conference, follow the steps below or click the link below to join the zoom meeting </b></p>
                    <hr></hr>
                    <p><b><a href="https://us05web.zoom.us/j/84129144523?pwd=S0JGaDhOc1ZxeIRLV1BrSHdDaFFIQT09">https://us05web.zoom.us/j/84129144523?pwd=S0JGaDhOc1ZxeIRLV1BrSHdDaFFIQT09</a></b></p>
                    <hr></hr>
                    <p><b>Meeting ID : 841 2914 4523</b></p>
                    <hr></hr>
                    <p><b>Passcode : Z1u2pw</b></p>
                    <hr></hr>
                </div>
                <div className="box">
                    <div className="box-h">
                        <div style={{ display: "flex", marginLeft: "20px", marginTop: "auto", marginBottom: "auto" }}>
                            <img src={an} alt="" width="36px" height="36px" />
                        </div>
                        <div style={{ display: "flex", marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>Android</div>
                    </div>
                    <svg onClick={this.an} width="50px" height="50px" style={{ margin: "auto" }} fill="#2da5ff" viewBox="0 0 20 20">
                        <path id="a-n" d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                    </svg>
                    <div className="bo" id="aa" style={{ display: "none" }}>
                        <p>1. Open the Zoom mobile app. If you have not downloaded the Zoom mobile app yet, you can download it from the Google Play Store.</p>
                        <p>2.  Join a meeting using one of these methods:</p>
                        <li >Tap <b>Join a meeting</b> if you want to join without signing in.</li>

                        <li >Sign in to Zoom then tap Join.</li>
                        <img src={b} style={{maxWidth:"98px"}} alt="" />
                        <p>3. Enter the meeting ID number and your display name.</p>
                        <li >If you're signed in, change your name if you don't want your default name to appear.</li>
                        <li >If you're not signed in, enter a display name.</li>

                        <p>4. Select if you would like to connect audio and/or video and click Join.</p>
                        <img src={e} style={{maxWidth:"300px"}} alt="" />
                    </div>
                </div>
                <div className="box">
                    <div className="box-h">
                        <div style={{ display: "flex", marginLeft: "20px", marginTop: "auto", marginBottom: "auto" }}>
                            <img src={mac} alt="" width="36px" height="36px" />
                        </div>
                        <div style={{ display: "flex", marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>iOS</div>
                    </div>
                    <svg onClick={this.io} width="50px" height="50px" style={{ margin: "auto" }} fill="#2da5ff" viewBox="0 0 20 20">
                        <path id="i-o" d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                    </svg>
                    <div className="bo" id="i" style={{ display: "none" }}>
                        <p>1. Open the Zoom mobile app. If you have not downloaded the Zoom mobile app yet, you can download it from the App Store.</p>
                        <p>2.  Join a meeting using one of these methods:</p>
                        <li >Tap <b>Join a meeting</b> if you want to join without signing in.</li>

                        <li >Sign in to Zoom then tap Join.</li>
                        <img src={b} style={{maxWidth:"98px"}} alt="" />
                        <p>3. Enter the meeting ID number and your display name.</p>
                        <li >If you're signed in, change your name if you don't want your default name to appear.</li>
                        <li >If you're not signed in, enter a display name.</li>

                        <p>4. Select if you would like to connect audio and/or video and click Join.</p>
                        <img src={e2} style={{maxWidth:"300px"}} alt="" />
                    </div>
                </div>
                <div className="box">
                    <div className="box-h">
                        <div style={{ display: "flex", marginLeft: "20px", marginTop: "auto", marginBottom: "auto" }}>
                            <img src={win} alt="" width="36px" height="36px" />
                        </div>
                        <div style={{ display: "flex", marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>    Windows | </div>
                        <div style={{ display: "flex", marginLeft: "20px", marginTop: "auto", marginBottom: "auto" }}>
                            <img src={mac} alt="" width="36px" height="36px" />
                        </div>
                        <div style={{ display: "flex", marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>    Mac</div>
                    </div>
                    <svg onClick={this.wi} width="50px" height="50px" style={{ margin: "auto" }} fill="#2da5ff" viewBox="0 0 20 20">
                        <path id="w-b" d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                    </svg>
                    <div className="bo" id="w" style={{ display: "none" }}>
                        <p>1. Open the Zoom desktop client.</p>
                        <p>2.  Join a meeting using one of these methods:</p>
                        <li >Click <b>Join a meeting</b> if you want to join without signing in.</li>
                        <img src={a} style={{maxWidth:"343px"}} alt="" />
                        <li >Sign in to Zoom then click Join.</li>
                        <img src={b} style={{maxWidth:"98px"}} alt="" />
                        <p>3. Enter the meeting ID number and your display name.</p>
                        <li >If you're signed in, change your name if you don't want your default name to appear.</li>
                        <li >If you're not signed in, enter a display name.</li>
                        <img src={c} style={{maxWidth:"363px"}} alt="" />
                        <p>4. Select if you would like to connect audio and/or video and click Join.</p>
                    </div>
                </div>

                <div className="box">
                    <div className="box-h">
                        <div style={{ display: "flex", marginLeft: "20px", marginTop: "auto", marginBottom: "auto" }}>
                            <img src={li} alt="" width="36px" height="36px" />
                        </div>
                        <div style={{ display: "flex", marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>Linux</div>
                    </div>
                    <svg onClick={this.li} width="50px" height="50px" style={{ margin: "auto" }} fill="#2da5ff" viewBox="0 0 20 20">
                        <path id="l-i" d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                    </svg>

                    <div className="bo" id="l" style={{ display: "none" }}>
                        <p>1. Open the Zoom desktop client.</p>
                        <p>2.  Join a meeting using one of these methods:</p>
                        <li >Click <b>Join a meeting</b> if you want to join without signing in.</li>

                        <li >Sign in to Zoom then click Join.</li>
                        <img src={b} style={{maxWidth:"98px"}} alt="" />
                        <p>3. Enter the meeting ID number and your display name.</p>
                        <li >If you're signed in, change your name if you don't want your default name to appear.</li>
                        <li >If you're not signed in, enter a display name.</li>

                        <p>4. Select if you would like to connect audio and/or video and click Join.</p>
                        <img src={d} style={{maxWidth:"300px"}} alt="" />
                    </div>
                </div>

                <div className="box">
                    <div className="box-h">
                        <div style={{ display: "flex", marginLeft: "20px", marginTop: "auto", marginBottom: "auto" }}>
                            <img src={we} alt="" width="36px" height="36px" />
                        </div>
                        <div style={{ display: "flex", marginLeft: "10px", marginTop: "auto", marginBottom: "auto" }}>Web Browser</div>
                    </div>
                    <svg onClick={this.br} width="50px" height="50px" style={{ margin: "auto" }} fill="#2da5ff" viewBox="0 0 20 20">
                        <path id="b-r" d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
                    </svg>
                    <div id="br" style={{ display: "none" }}>
                        <p><b>Google Chrome</b></p>
                        <div className="bo">
                            <p>1. Open Chrome.</p>
                            <p>2. Go to join.zoom.us.</p>
                            <p>3. Enter your meeting ID provided by the host/organizer.</p>
                            <img src={f} style={{maxWidth:"564px"}} alt="" />

                            <p>4. Click Join</p>
                            <li >If this is your first time joining from Google Chrome, you will be asked to open the Zoom client to join the meeting.</li>
                            <li >You can check Always open these types of links in the associated app to skip this step in the future.</li>
                            <li >Click Open Zoom Meetings (PC) or Open zoom.us (Mac).</li>
                            <img src={g} style={{maxWidth:"447px"}} alt="" />
                        </div>
                        <p><b>Safari</b></p>
                        <div className="bo">
                            <p>1. Open Safari.</p>
                            <p>2. Go to join.zoom.us.</p>
                            <p>3. Enter your meeting ID provided by the host/organizer.</p>
                            <img src={f} style={{maxWidth:"564px"}} alt="" />

                            <p>4. Click Join</p>
                            <p>When asked if you want to open zoom.us, click Allow.</p>
                            <img src={h}style={{maxWidth:"453px"}} alt="" />
                        </div>
                        <p><b>Microsoft Edge or Internet Explorer</b></p>
                        <div className="bo">
                            <p>1. Open Edge or Internet Explorer.</p>
                            <p>2. Go to join.zoom.us.</p>
                            <p>3. Enter your meeting ID provided by the host/organizer.</p>
                            <img src={f} style={{maxWidth:"564px"}} alt="" />

                            <p>4. Click Join</p>

                        </div>
                        <p><b>Mozilla Firefox</b></p>
                        <div className="bo">
                            <p>1. Open Firefox.</p>
                            <p>2. Go to join.zoom.us.</p>
                            <p>3. Enter your meeting ID provided by the host/organizer.</p>
                            <img src={f} style={{maxWidth:"564px"}} alt="" />

                            <p>4. Click Join</p>
                            <li>If this is your first time joining from Firefox, you may be asked to open Zoom or the Zoom installer package.</li>
                            <li>To skip this step in the future, check Remember my choose for zoommtg links.</li>
                            <li>Click Open Link.</li>
                            <img src={i} style={{maxWidth:"314px"}} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}



