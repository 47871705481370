import { useState } from "react";
import { Link } from "react-router-dom";
import { PayPalButton } from 'react-paypal-button-v2';
import axios from "axios";

export default function OasisDevotional(){
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState();
  const [approve, setApprove] = useState();
const createOrder = () => {
  setError();
  setApprove();
  return axios.post("/api/payment/oasis-devotional", {},{
    headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
  }})
  .then(response => {
    return response.data.orderID;
  },
  error => {
    setError(`Payment failed - ${error.message}`);
  });
};
const onApprove = (data) => {
  setLoading(true);
  setApprove();
  setError();
  return axios.post("/api/oasis-devotional/paypal-transaction-complete", {
    orderID: data.orderID,
  },{
    headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
  }})
  .then(response => {
    setSuccess(true);
    setApprove();
    setLoading(false);
  },
  error => {
    setError(`Transaction could not be completed - ${error.message}`);
    setApprove(data);
    setLoading(false);
  });
};
const onApproves = (data) => {
  setLoading(true);
  setApprove();
  setError();
  return axios.post("/api/oasis-devotional/paypal-transaction-complete-again", {
    orderID: data.orderID,
  },{
    headers: {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
  }})
  .then(response => {
    setSuccess(true);
    setApprove();
    setLoading(false);
  },
  error => {
    setError(`Transaction could not be completed - ${error.message}`);
    setApprove(data);
    setLoading(false);
  });
};
    return(
        <div>
            <div className="book-bg">
                <div className="book-text-container">
                <h2 className="book-release">New Release</h2>
                <h1 className="book-title">Oasis Devotional</h1>
                <p className="book-description">Oasis Devotional is a transformative eBook that offers a refreshing and enriching experience for your spiritual journey.
                With its accessible language and relatable anecdotes, Oasis Devotional is suitable for both beginners and seasoned spiritual seekers alike.
                Oasis Devotional offers a diverse range of teachings that will empower you to live a more fulfilling and purposeful life.</p>
                <h2 className="book-author">Author: Rev Ann E. Ekpah</h2>
                <h2 className="book-price">$5.00</h2>
<PayPalButton
   createOrder={createOrder}
   onApprove={onApprove}
   ></PayPalButton>
   {loading&&(<div className="load"></div>)}
   {error && (
                <div className="card-error" role="alert">
                    {error}
                </div>
            )}
            {approve&&(<button className="re-bb bg-blue" onClick={()=>onApproves(approve)}>Try Again</button>)}
            {success ? (<p className={success ? "result-message" : "result-message hidden"}>
                Your Payment have been made successfully and the eBook has been sent successfully to your Email. <Link to="/contact-us">Contact me for reciept.</Link>
      </p>) : (<p></p>)}
      
                </div>
                <div className="book-img-container"><img className="book-img" src="./oasis-devotional.png"/></div>
            </div>
        </div>
    );
}