import React from "react";


export default class Video extends React.Component {
    render() {
        let pathname = window.location.pathname; 
        const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
        const lin = getLastItem(pathname);
        return (

            <div style={{textAlign:"center"}}>
                <iframe title="video" width="100%" className="vid" src={"https://www.youtube.com/embed/"+lin} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>
        );
    }
}



