import React from 'react';
import { useState } from "react";
var validator = require('validator');
const axios = require("axios");
export default function Contact() {
    const [success, succ] = useState("");
    const [err, setLoginStatus] = useState("");
    const [first, fi] = useState("");
    const [last, la] = useState("");
    const [email, em] = useState("");
    const [phonenumber, ph] = useState("");
    const [req, re] = useState("");
    function sendEmail(e) {
        if (first && last && email && phonenumber && req) {

            document.getElementById("load").style.display = "flex";
document.getElementById("se").disabled = true;
            succ("");
            setLoginStatus("");

            e.preventDefault();
           
            const first_name = first;
            const last_name = last;
            const phone = phonenumber;
            const request = req;
 const config = {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
                }
            };
            axios
                .post("https://revannekpah.org/contact-us", {
                    first_name,
                    last_name,
                    email,
                    phone,
                    request

                },config)
                .then(
                    response => {
                        document.getElementById("se").removeAttribute('disabled')
                        setLoginStatus("");
                         fi("");
                    la("");
                    em("");
                    ph("");
                    re("");
                        document.getElementById("load").style.display = "none";
                        succ("Thanks For Contacting Us");
                        //console.log(response);
                    },
                error => {
                        document.getElementById("load").style.display = "none";
                        document.getElementById("se").removeAttribute('disabled')
                        succ("");
                        setLoginStatus("An Error Occured Please Try Again Later");
                        //  console.log(error);
                    }
                );
        } else {
            e.preventDefault();
            if (!first) {
                setLoginStatus("First Name Cannot Be Empty");
            }
            else if (!last) {
                setLoginStatus("Last Name Cannot Be Empty");
            }
            else if (!validator.isEmail(email)) {
                setLoginStatus("Please Enter Valid Email Address.");
            }
            else if (!validator.isMobilePhone(phonenumber)) {
                setLoginStatus("Please Enter Valid Phone Number.");
            }
            else if (!req) {
                setLoginStatus("Message Cannot Be Empty");
            }
            else {
                e.preventDefault();
                setLoginStatus("Please Fill In All Fields");
            }
        }
    }

    return (
        <div style={{ textAlign: "center" }}>
            <div className="m-b-t">Contact Us</div>
            <form className="contact-form" onSubmit={sendEmail}>
                <input type="text" placeholder="First Name"
                    value={first}
                    onChange={(e) => {
                        fi(e.target.value);

                    }} name="first_name" />

                <input type="text" placeholder="Last Name"
                    value={last}
                    onChange={(e) => {
                        la(e.target.value);

                    }} name="last_name" />

                <input type="email" name="email"
                    value={email}
                    onChange={(e) => {
                        em(e.target.value);

                    }} placeholder="Email" />
                <input type="number"
                    value={phonenumber}
                    onChange={(e) => {
                        ph(e.target.value);

                    }} placeholder="Phone Number" name="phone" />
                <div> <textarea name="request"
                    value={req}
                    onChange={(e) => {
                        re(e.target.value);

                    }} placeholder="Message" />
                    <div className="y">{success}</div>
                    <div className="u">{err}</div>
                    <div className="load" id="load"></div>
                    <button type="submit" id="se">Submit</button></div>

            </form>

        </div>
    );
}