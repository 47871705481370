import React from "react";
import { Link } from "react-router-dom";
//import InfiniteScroll from "react-infinite-scroll-component";

export default class About extends React.Component {
    /*componentDidMount() {
        console.log('componentDidMount colling...');
        fetch('https://www.googleapis.com/youtube/v3/search?part=snippet&order=date&channelId=UCobXt2pf_bpXpBrz-i0zIIg&key=AIzaSyB188o7dLtxNCgMuG7Iy58xu66Wwvdkga4&maxResults=50')
            .then(response => {
                response.json()
                console.log(response)
            }
            )
            .then(data => console.log(data));
    }*/
    render() {        return (

            <div style={{textAlign:"center"}}>
                <div className="m-b-t">Videos</div>
            <div style={{ display: "flex", placeContent: "space-around", flexWrap: "wrap" }}>
            <div className="minis" style={{ backgroundImage: `url("/z.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                    position: "absolute",
                    width: "auto",
                    height: "auto",
                    top: "20px",
                    bottom: "20px",
                    left: "20px",
                    right: "20px",
                    backgroundColor: "rgba(0,0,0,.6)"
                }}> <div style={{
                    display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "2"
                    }}>It is possible, Don't Doubt</div>
                    <Link to="/video/S5PPCJ-fEwQ"> <button className="re-bb">View</button></Link></div></div>
                <div className="minis" style={{ backgroundImage: `url("/y.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                    position: "absolute",
                    width: "auto",
                    height: "auto",
                    top: "20px",
                    bottom: "20px",
                    left: "20px",
                    right: "20px",
                    backgroundColor: "rgba(0,0,0,.6)"
                }}> <div style={{
                    display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "2"
                    }}>Oasis movie theater:.Titled the praying prevailing woman 1 peter 4: 10 - 11</div>
                    <Link to="/video/siXQfBjawEs"> <button className="re-bb">View</button></Link></div></div>
                <div className="minis" style={{ backgroundImage: `url("/r.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                    position: "absolute",
                    width: "auto",
                    height: "auto",
                    top: "20px",
                    bottom: "20px",
                    left: "20px",
                    right: "20px",
                    backgroundColor: "rgba(0,0,0,.6)"
                }}> <div style={{
                    display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "2"
                    }}>Be a warrior &#x1F4AA; in the face adversity . Isaiah 41 : 13</div>
                    <Link to="/video/W_zeQKpf2lM"> <button className="re-bb">View</button></Link></div></div>
                <div className="minis" style={{ backgroundImage: `url("/x.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                    position: "absolute",
                    width: "auto",
                    height: "auto",
                    top: "20px",
                    bottom: "20px",
                    left: "20px",
                    right: "20px",
                    backgroundColor: "rgba(0,0,0,.6)"
                }}> <div style={{
                    display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "2"
                    }}>Breaking Generational  Curses midnight prayer ,with Oasis God Ministry. Gen: 27: 29. Isa 49: 23-26.</div>
                    <Link to="/video/SDzxLoWBAgo"> <button className="re-bb">View</button></Link></div></div>
                <div className="minis" style={{ backgroundImage: `url("/p.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                    position: "absolute",
                    width: "auto",
                    height: "auto",
                    top: "20px",
                    bottom: "20px",
                    left: "20px",
                    right: "20px",
                    backgroundColor: "rgba(0,0,0,.6)"
                }}> <div style={{
                    display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "2"
                }}>WARFARE ROOM : Where Glories , Virtues and Destines. Psalm 46</div>
                    <Link to="/video/-8q-q8R5mWo"> <button className="re-bb">View</button></Link></div></div>
                <div className="minis" style={{ backgroundImage: `url("/s.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                    position: "absolute",
                    width: "auto",
                    height: "auto",
                    top: "20px",
                    bottom: "20px",
                    left: "20px",
                    right: "20px",
                    backgroundColor: "rgba(0,0,0,.6)"
                }}> <div style={{
                    display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "2"
                    }}>WARFARE ROOM: Where Glory and Destiny is Restored! with Rev Esther Idoko</div>
                    <Link to="/video/6EJ0jydLXLE"> <button className="re-bb">View</button></Link></div></div>
                <div className="minis" style={{ backgroundImage: `url("/t.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                    position: "absolute",
                    width: "auto",
                    height: "auto",
                    top: "20px",
                    bottom: "20px",
                    left: "20px",
                    right: "20px",
                    backgroundColor: "rgba(0,0,0,.6)"
                }}> <div style={{
                    display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "2"
                    }}>Bible Teaching On How TO Break Curses II With Rev Esther Idoko</div>
                    <Link to="/video/YBWkDCKD2p8"> <button className="re-bb">View</button></Link></div></div>
                <div className="minis" style={{ backgroundImage: `url("/u.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                    position: "absolute",
                    width: "auto",
                    height: "auto",
                    top: "20px",
                    bottom: "20px",
                    left: "20px",
                    right: "20px",
                    backgroundColor: "rgba(0,0,0,.6)"
                }}> <div style={{
                    display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "2"
                    }}>BIBLE STUDY: The Quality of a Man. Gen1:27</div>
                    <Link to="/video/LtLQPYBI0sY"> <button className="re-bb">View</button></Link></div></div>
                <div className="minis" style={{ backgroundImage: `url("/u.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                    position: "absolute",
                    width: "auto",
                    height: "auto",
                    top: "20px",
                    bottom: "20px",
                    left: "20px",
                    right: "20px",
                    backgroundColor: "rgba(0,0,0,.6)"
                }}> <div style={{
                    display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "2"
                }}>BIBLE STUDY: The Quality of a Man. Gen1:27</div>
                    <Link to="/video/LtLQPYBI0sY"> <button className="re-bb">View</button></Link></div></div>
                <div className="minis" style={{ backgroundImage: `url("/v.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                    position: "absolute",
                    width: "auto",
                    height: "auto",
                    top: "20px",
                    bottom: "20px",
                    left: "20px",
                    right: "20px",
                    backgroundColor: "rgba(0,0,0,.6)"
                }}> <div style={{
                    display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "2"
                    }}>Pay attention to  this .  &#x1F64C; And overcome In Daily Battle. 2 Corinthian 11: 14. 2 Corinth 10: 3 - 5 by Rev Esther Idoko 3 days ago 25 minutes 40 views</div>
                    <Link to="/video/vzED5tHO-Gw"> <button className="re-bb">View</button></Link></div></div>
                <div className="minis" style={{ backgroundImage: `url("/w.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                    position: "absolute",
                    width: "auto",
                    height: "auto",
                    top: "20px",
                    bottom: "20px",
                    left: "20px",
                    right: "20px",
                    backgroundColor: "rgba(0,0,0,.6)"
                }}> <div style={{
                    display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: "2"
                    }}>Powerful Declaration for Victory &#9996; proverb 18:21
</div>
                    <Link to="/video/CGCRjb5csWc"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/a.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                        <div style={{
                            position:"absolute",
                            width: "auto",
                            height: "auto",
                            top: "20px",
                            bottom: "20px",
                            left: "20px",
                            right: "20px",
                            backgroundColor: "rgba(0,0,0,.6)"}}>       <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
   WebkitBoxOrient:"vertical",
                    textOverflow: "ellipsis",
                                WebkitLineClamp: "2" }}>Warfare Room: Were Glory Virtue are Restored. Psalm 27 Job 42:10</div>
                            <Link to="/video/L-N8USlxp-w"> <button className="re-bb">View</button></Link></div></div>
                 
                    <div className="minis" style={{ backgroundImage: `url("/c.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Bible study: Walk Through the storm. Don't Stop (Father Abraham legacy)</div>
                        <Link to="/video/nupO6X4hBrw"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/d.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Warfare Room: Were Destiny Glory and Virtue are being Restored Exodus 14:14</div>
                        <Link to="/video/FzC68V0RTn0"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/e.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Bible study: The battle is not yours' but  of the lord. 2 chronicles 20:15</div>
                        <Link to="/video/EHYUFu8OweY"> <button className="re-bb">View</button></Link></div></div>
                 
                    <div className="minis" style={{ backgroundImage: `url("/i.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>warfare Room: Where glory virtue Destiny are Restored Jeremiah 1:5  John 16:33</div>
                        <Link to="/video/e1zm20Mfm44"><button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/j.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Bible study: Repentance is Necessary Act 17: 30</div>
                        <Link to="/video/rMGXUEdp-pc">  <button className="re-bb">View</button></Link></div></div><div className="minis" style={{ backgroundImage: `url("/k.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                            position: "absolute",
                            width: "auto",
                            height: "auto",
                            top: "20px",
                            bottom: "20px",
                            left: "20px",
                            right: "20px",
                            backgroundColor: "rgba(0,0,0,.6)"
                        }}> <div style={{
                            display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: "2"
                    }}>warfare Room: Where Glory Virtues are Restored 1 peter 2: 6 Psalm 18:2</div>
                        <Link to="/video/ztBmnCI1orw"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/l.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Living a successful life, that ushers you to New year. Isaiah 40: 28-30</div>
                        <Link to="/video/WLgKsI06L_w"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/m.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                        }}>Bible Teaching On How TO Break Curses With Rev Esther Idoko</div>
                        <Link to="/video/NyqYt7a5GVI"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/n.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                        }}>Broken Curses through Intercession with Rev Esther Idoko</div>
                        <Link to="/video/pxtyJjolpm4"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/o.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                        }}>Bible study: God Call To plenty ( Abrahamic Legacy) Gen 12 - 25</div>
                        <Link to="/video/jwD6XzgtaDQ"> <button className="re-bb">View</button></Link></div></div>
                  
                    <div className="minis" style={{ backgroundImage: `url("/q.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                        }}>Bible study: Series 2. Right path,  Right Choice.  Mathew 7: 13 - 14</div>
                        <Link to="/video/o5bIKxc2j3s"> <button className="re-bb">View</button></Link></div></div>
                </div>
            </div>
        );
    }
}



