import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";

export default class Blog extends React.Component {
    state = {
        dat: false,
        netw:false
    }
    tr = () => {
        this.setState({ netw: false });
       const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
            }
        };
        axios
            .get("https://revannekpah.org/blog-post-datas", config).then(
                response => {
                    this.setState({ dat: response.data });

                },
                error => {
                    this.setState({ netw: true });
                });

    }
    componentDidMount() {
      const config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
            }
        };
            axios
                .get("https://revannekpah.org/blog-post-datas", config).then(
                    response => {
                        this.setState({ dat: response.data });
                       
                    },
                    error => {
                        this.setState({ netw: true });  
            });

        }
    render() {
        const { dat,netw } = this.state;
    return (
       
        <div>
           
            <h3 className="tbo">Blog Posts</h3>
            <div style={{ margin: "20px" }}>
                 <div style={{ textAlign: "center", margin: "auto" }}>
                    <div style={{ display: "flex", placeContent: "space-around", flexWrap: "wrap" }}>
                        {dat ? (<div style={{ display: "flex", placeContent: "space-around", flexWrap: "wrap" }}> {
                            dat.sort((a, b) => a.id - b.id).reverse().map((d) => (
                                <div key={d.id}className="minis"><div style={{ margin: "auto" }}>{d.title}</div>
                                    <Link to={"/blog/" + d.link}>  <button className="re-bb">Read More</button></Link></div>
                            ))}</div>) : (<div> 
                                {netw ? (<div onClick={this.tr}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="80px" height="80px" viewBox="0 0 24 24" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M21.0303 4.83038C21.3232 4.53749 21.3232 4.06261 21.0303 3.76972C20.7374 3.47683 20.2626 3.47683 19.9697 3.76972L3.96967 19.7697C3.67678 20.0626 3.67678 20.5375 3.96967 20.8304C4.26256 21.1233 4.73744 21.1233 5.03033 20.8304L7.11065 18.7501H18.5233C20.9961 18.7501 23.0008 16.7454 23.0008 14.2725C23.0008 11.7996 20.9961 9.79493 18.5233 9.79493C18.4592 9.79493 18.3955 9.79628 18.3321 9.79895C18.2944 9.15027 18.1424 8.53227 17.8959 7.96479L21.0303 4.83038ZM16.7186 9.14209L8.61065 17.2501H18.5233C20.1677 17.2501 21.5008 15.917 21.5008 14.2725C21.5008 12.628 20.1677 11.2949 18.5233 11.2949C18.2557 11.2949 17.9975 11.33 17.7524 11.3955C17.5122 11.4596 17.2558 11.4006 17.0679 11.2378C16.8799 11.075 16.7849 10.8297 16.8141 10.5828C16.8321 10.4306 16.8414 10.2755 16.8414 10.1178C16.8414 9.78093 16.7987 9.45399 16.7186 9.14209Z" fill="blue" />
                                        <path d="M12.9319 4.70837C14.0388 4.70837 15.068 5.04083 15.9252 5.61134C16.0521 5.69579 16.0649 5.87451 15.9571 5.9823L15.2295 6.70991C15.1455 6.79392 15.0144 6.80644 14.912 6.74617C14.3313 6.4044 13.6545 6.20837 12.9319 6.20837C11.3816 6.20837 10.0406 7.1107 9.40813 8.42218C9.23808 8.77479 8.82543 8.9373 8.46061 8.79534C7.96987 8.60439 7.43541 8.49926 6.87461 8.49926C4.45814 8.49926 2.49921 10.4582 2.49921 12.8747C2.49921 14.521 3.40846 15.9549 4.75218 16.7017C4.90497 16.7866 4.94313 16.9963 4.81953 17.1199L4.09641 17.843C4.01666 17.9227 3.89307 17.9397 3.79705 17.8805C2.1183 16.8462 0.999207 14.9911 0.999207 12.8747C0.999207 9.62976 3.62971 6.99925 6.87461 6.99925C7.39427 6.99925 7.89899 7.0669 8.38002 7.19408C9.34177 5.69979 11.0205 4.70837 12.9319 4.70837Z" fill="blue" />
                                    </svg>
                                    <h3 style={{ margin: 0 }}>No Internet Connection. <span style={{ color: "blue" }}>Try Again</span></h3>
                               </div>) : (<div className="load" style={{ display: "block" }}></div>)}</div>)}
                     
                    </div>
                </div>
            </div>
        </div>
    );
}
}



