import React from "react";
import { Link } from "react-router-dom";

export default class Sound extends React.Component {
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <div className="m-b-t">Sound Bible Teaching</div>
                <div className="b-box">
                    <hr></hr>
                    <p>Romans 12:2. "Do not conform to the pattern of this
world, but be transformed by the renewing of your mind. Then you will
be able to test and approve what God's will is-his good, pleasing and
perfect will."</p>
                    <div style={{ textAlign: "left" }}>
                        <hr></hr>
                        <p>&#9679;Reinstatement of broken homes</p>
                        <hr></hr>
                        <p>&#9679;Teaching youths the best way of life</p>
                        <hr></hr>
                        <p>&#9679;Evangelising to lost souls to discover and recover their destiny</p>
                        <hr></hr>
                    </div>
                </div>
                <div style={{ display: "flex", placeContent: "space-around", flexWrap: "wrap" }}>
                    <div className="minis" style={{ backgroundImage: `url("/c.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Bible study: Walk Through the storm. Don't Stop (Father Abraham legacy)</div>
                        <Link to="/video/nupO6X4hBrw"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/e.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Bible study: The battle is not yours' but  of the lord. 2 chronicles 20:15</div>
                        <Link to="/video/EHYUFu8OweY"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/f.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Bible study: The battle is not yours' but  of the lord. 2 chronicles 20:15</div>
                        <Link to="/video/dE6m8gn2Mk4"><button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/g.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Bible study: Cast your Burden on Jesus Christ. phillipian 4:6-7</div>
                        <Link to="/video/rqloTe2HMig"><button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/j.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Bible study: Repentance is Necessary Act 17: 30</div>
                        <Link to="/video/rMGXUEdp-pc">  <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/m.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Bible Teaching On How TO Break Curses With Rev Esther Idoko</div>
                        <Link to="/video/NyqYt7a5GVI"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/o.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Bible study: God Call To plenty ( Abrahamic Legacy) Gen 12 - 25</div>
                        <Link to="/video/jwD6XzgtaDQ"> <button className="re-bb">View</button></Link></div></div>
                </div>
            </div>
        );
    }
}



