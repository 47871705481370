import React from "react";
import axios from "axios";

export default class Pblog extends React.Component {
    state = {
        title: "",
        password: "",
        wrong: false,
        correct: false,
        loading: false,
        disabled:false
    }
    ChangeTitle = (e) => {
        this.setState({
            title: e.target.value
        });
    }
    onChangeHandler = event => {
        if (event.target.files[0]) {
            this.setState({
                selectedFile: event.target.files[0],
                loaded: 0,
                finane: event.target.files[0].name
            })
        }
    }
    ChangePassword = (e) => {
        this.setState({
            password: e.target.value
        });
    }
    onClickHandler = () => {
        this.setState({
            wrong: false,
            correct: false,
            loading: false,
            disabled: true
        });
       // var upld = this.state.finane.split('.').pop();
        if (!this.state.selectedFile) {
            this.setState({
                wrong: "Please Upload A File",
                disabled: false
            });
        } else if (this.state.finane.split('.').pop() !== 'pdf') {
            this.setState({
                wrong: "Please Upload A PDF File Only",
                disabled: false
            });
        } else if (!this.state.title) {
            this.setState({
                wrong: "Please Enter A Title",
                disabled: false
            });
        } else if (!this.state.password) {
            this.setState({
                wrong: "Please Enter A Password",
                disabled: false
            });
        } else{
            const data = new FormData()
            data.append('file', this.state.selectedFile);
            data.append('password', this.state.title)
            data.append('title', this.state.title)
            this.setState({
                wrong: false,
                correct: false,
                disabled: true,
                loading:true
            });
            axios.post("/check-password", { password: this.state.password }, {  }, {
                // receive two    parameter endpoint url ,form data
            })
                .then(res => {
                    if (res.data.check === true) {
                        axios.post("/upload-pdf", data, { }, {
                            // receive two    parameter endpoint url ,form data
                        })
                            .then(res => { 
                                this.setState({
                                    correct: "Blog Post Added Successfully",
                                    title: "",
                                    selectedFile: null,
                                    password: "",
                                    disabled: false,
                                    loading: false
                                });
                            }).catch(error => {
                                const wro = error.response?.data.error || error;
                                this.setState({
                                    wrong: wro,
                                    disabled: false,
                                    loading: false
                                });
                            });
                    } else {
                        this.setState({
                            wrong: "Wrong Password",
                            disabled: false,
                            loading: false
                        });
                    }
                }).catch(error => {
                    const wro = error.response?.data.error || error;
                    this.setState({
                        wrong: wro,
                        disabled: false,
                        loading:false
                    });
                });
        }
    
    }
   constructor(props) {
       super(props);
       this.state = {
           selectedFile: null,
           finane:""
       }
    }
    render() {
       
        return (
            <div style={{ textAlign: "center" }}>
                <div className="m-b-t">Post A Blog</div>
                <div className="contact-form">
                        <div style={{display:"flex"}}>
                        <label style={{ margin: "auto", display: "flex"}}>

                            <svg viewBox="-50 -50 500 500" className="sv"><g fill="blue" fillRule="evenodd"><path xmlns="http://www.w3.org/2000/svg" d="M372.939,264.641c-6.641,0-12.03,5.39-12.03,12.03v84.212H24.061v-84.212c0-6.641-5.39-12.03-12.03-12.03    S0,270.031,0,276.671v96.242c0,6.641,5.39,12.03,12.03,12.03h360.909c6.641,0,12.03-5.39,12.03-12.03v-96.242    C384.97,270.019,379.58,264.641,372.939,264.641z" /><path xmlns="http://www.w3.org/2000/svg" d="M117.067,103.507l63.46-62.558v235.71c0,6.641,5.438,12.03,12.151,12.03c6.713,0,12.151-5.39,12.151-12.03V40.95    l63.46,62.558c4.74,4.704,12.439,4.704,17.179,0c4.74-4.704,4.752-12.319,0-17.011l-84.2-82.997    c-4.692-4.656-12.584-4.608-17.191,0L99.888,86.496c-4.752,4.704-4.74,12.319,0,17.011    C104.628,108.211,112.327,108.211,117.067,103.507z" /></g></svg>
                                                <input
                                type="file"
                                accept="application/pdf"
                                                    name="file"
                                                    id="input-files"
                                                    className="form-control-file border"
                                                   
                                onChange={this.onChangeHandler}
                                                    style={{display:"none"}}
                            />
                           <h4> Upload A PDF File Only</h4>
                        </label>
                      
                                                   
                                                </div>  <h5>{this.state.finane}</h5><br></br>
                    <label htmlFor="fname">Title:</label><br></br>
                    <input type="text" placeholder="Title"
                        value={this.state.title}

                        onChange={this.ChangeTitle}
                        name="blog_title" /><br></br>
                    <label htmlFor="fname">Password:</label><br></br>
                    <input type="password" placeholder="Password"
                        onChange={this.ChangePassword}
                        value={this.state.password}
                        name="blog_title" />

                    {this.state.wrong && (<div className="u">{this.state.wrong}</div>)}
                    {this.state.correct && (<div className="y">{this.state.correct}</div>)}
                    <button type="submit" disabled={this.state.disabled} onClick={this.onClickHandler} id="se">Post Blog {this.state.loading && (<div className="loader"></div>)}</button>

                </div>

            </div>
        );
    }
}



