import React from "react";
import { Link } from "react-router-dom";

export default class Deliver extends React.Component {
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <div className="m-b-t">School of Deliverance Ministry</div>
                <div className="b-box">
                    <hr></hr>
                    <p>Matthew 11:12. " And from the days of John the
Baptist until now the kingdom of heaven suffereth violence, and the violent
take it by force."</p>
                    <div style={{ textAlign: "left" }}>
                        <hr></hr>
                        <p>&#9679;A warfare room where glory, destiny, virtues are being restored.</p>
                        <hr></hr>
                        <p>&#9679;Teaching youths the best way of life</p>
                        <hr></hr>
                        <p>&#9679;How to speak in the spirit.</p>
                        <hr></hr>
                    </div>
                </div>
                <div className="m-b-t">Audio Messages</div>
                <audio
                    src="/WARFAREROOMAUDIO1.mp4"
                    controls
                />
                <audio
                    src="/WARFAREROOMAUDIO2.mp4"
                    controls
                />
                <div style={{ display: "flex", placeContent: "space-around", flexWrap: "wrap" }}>
                    <div className="minis" style={{ backgroundImage: `url("/a.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                        <div style={{
                            position: "absolute",
                            width: "auto",
                            height: "auto",
                            top: "20px",
                            bottom: "20px",
                            left: "20px",
                            right: "20px",
                            backgroundColor: "rgba(0,0,0,.6)"
                        }}>       <div style={{
                            display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: "2"
                        }}>Warfare Room: Were Glory Virtue are Restored. Psalm 27 Job 42:10</div>
                            <Link to="/video/L-N8USlxp-w"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/d.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>Warfare Room: Were Destiny Glory and Virtue are being Restored Exodus 14:14</div>
                        <Link to="/video/FzC68V0RTn0"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/i.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>warfare Room: Where glory virtue Destiny are Restored Jeremiah 1:5  John 16:33</div>
                        <Link to="/video/e1zm20Mfm44"><button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/k.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>warfare Room: Where Glory Virtues are Restored 1 peter 2: 6 Psalm 18:2</div>
                        <Link to="/video/ztBmnCI1orw"> <button className="re-bb">View</button></Link></div></div>
                    <div className="minis" style={{ backgroundImage: `url("/p.png")`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>  <div style={{
                        position: "absolute",
                        width: "auto",
                        height: "auto",
                        top: "20px",
                        bottom: "20px",
                        left: "20px",
                        right: "20px",
                        backgroundColor: "rgba(0,0,0,.6)"
                    }}> <div style={{
                        display: "-webkit-box", margin: "20px", color: "#4172ae", overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: "2"
                    }}>WARFARE ROOM : Where Glories , Virtues and Destines. Psalm 46</div>
                        <Link to="/video/-8q-q8R5mWo"> <button className="re-bb">View</button></Link></div></div>
                </div>
            </div>
        );
    }
}



