import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutFor from "../CheckoutFor";
import validator from 'validator';
// Make sure to call loadStripe outside of a component�s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
const promise = loadStripe("pk_live_51ItviZJgezenP1UJ7cM2BBhCz9TUjqWvERRVVbmCefJbFgmwAY5g9KT2UqVnrVIXjz8PaKHjO7RVI6UYI61QM6zf00EwrwEMuv");

export default class Partner extends React.Component {
    transfer = () => {
        this.setState({
            transfer: false
        })

    }
  onChangeUsername=(e)=> {
        this.setState({
            ann: e.target.value
        });
        if (validator.isEmpty(String(e.target.value))) {
            this.setState({
                btt: true
            });
        }else if (!validator.isNumeric(String(e.target.value))) {
        this.setState({
            btt: true
        });
    } else {
          this.setState({
              btt: false
          });
      }
    }
    constructor(props) {
        super(props);
        this.state = {
            transfer: true,
            ann: true,
            btt: true
        }
        
    }
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                {this.state.transfer?(  <div>
                    <div className="m-b-t" style={{ textAlign: "center" }}>Partner With Rev Ann E. Ekpah's Ministry</div>
                    <div className="b-boe" style={{ backgroundColor: "white", color: "white", boxShadow: "0px 0px 0px 0.5px rgb(50 50 93 / 10%), 0px 2px 5px 0px rgb(50 50 93 / 10%), 0px 1px 1.5px 0px rgb(0 0 0 / 7%)",
                        borderRadius: "7px", marginBottom: "30px" }}>
                        <div className="com" >  USD($)  <input type="number" onChange={this.onChangeUsername}
                            placeholder="" />
                        </div>
                        <button disabled={this.state.btt} className="bbb" onClick={this.transfer} type="submit"><div style={{ margin: "auto" }}>Partner</div> <svg style={{ margin: "auto",marginLeft:"5px" }} height="20px" viewBox="0 0 20 20">
	<path d="M1.729,9.212h14.656l-4.184-4.184c-0.307-0.306-0.307-0.801,0-1.107c0.305-0.306,0.801-0.306,1.106,0
	l5.481,5.482c0.018,0.014,0.037,0.019,0.053,0.034c0.181,0.181,0.242,0.425,0.209,0.66c-0.004,0.038-0.012,0.071-0.021,0.109
	c-0.028,0.098-0.075,0.188-0.143,0.271c-0.021,0.026-0.021,0.061-0.045,0.085c-0.015,0.016-0.034,0.02-0.051,0.033l-5.483,5.483
	c-0.306,0.307-0.802,0.307-1.106,0c-0.307-0.305-0.307-0.801,0-1.105l4.184-4.185H1.729c-0.436,0-0.788-0.353-0.788-0.788
	S1.293,9.212,1.729,9.212z"></path>
                </svg></button>
                </div>
              
                </div>):(
                 <div className="App"> <Elements stripe={promise}>
                            <CheckoutFor amm={this.state.ann} />
                </Elements></div>
                )}
                  
               
            </div>
        );
    }
}



