import React from "react";
import { Link } from "react-router-dom";

export default class Join extends React.Component {
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <div className="m-b-t">Join A Conference.</div>
                <div style={{width:"fit-content",margin:"auto"}}>  <Link to="/friday-prayer-and-fasting" style={{ display: "flex", margin: "auto", width: "fit-content" }}> <div style={{ display: "flex", margin: "auto" }}> <svg viewBox="0 0 20 20" width="100px" height="100px" fill="blue">
                    <path d="M17.919,4.633l-3.833,2.48V6.371c0-1-0.815-1.815-1.816-1.815H3.191c-1.001,0-1.816,0.814-1.816,1.815v7.261c0,1.001,0.815,1.815,1.816,1.815h9.079c1.001,0,1.816-0.814,1.816-1.815v-0.739l3.833,2.478c0.428,0.226,0.706-0.157,0.706-0.377V5.01C18.625,4.787,18.374,4.378,17.919,4.633 M13.178,13.632c0,0.501-0.406,0.907-0.908,0.907H3.191c-0.501,0-0.908-0.406-0.908-0.907V6.371c0-0.501,0.407-0.907,0.908-0.907h9.079c0.502,0,0.908,0.406,0.908,0.907V13.632zM17.717,14.158l-3.631-2.348V8.193l3.631-2.348V14.158z"></path>
                </svg><div style={{ color: "blue", fontSize: "20px", fontWeight: "bolder", margin: "20px", marginTop: "auto", marginBottom: "auto" }}>Join the friday - prayer and fasting</div></div></Link>
                <Link to="/bible-study" style={{ display: "flex",  width: "fit-content" }}> <div style={{ display: "flex", margin: "auto" }}> <svg viewBox="0 0 20 20" width="100px" height="100px" fill="blue">
                    <path d="M17.919,4.633l-3.833,2.48V6.371c0-1-0.815-1.815-1.816-1.815H3.191c-1.001,0-1.816,0.814-1.816,1.815v7.261c0,1.001,0.815,1.815,1.816,1.815h9.079c1.001,0,1.816-0.814,1.816-1.815v-0.739l3.833,2.478c0.428,0.226,0.706-0.157,0.706-0.377V5.01C18.625,4.787,18.374,4.378,17.919,4.633 M13.178,13.632c0,0.501-0.406,0.907-0.908,0.907H3.191c-0.501,0-0.908-0.406-0.908-0.907V6.371c0-0.501,0.407-0.907,0.908-0.907h9.079c0.502,0,0.908,0.406,0.908,0.907V13.632zM17.717,14.158l-3.631-2.348V8.193l3.631-2.348V14.158z"></path>
                </svg><div style={{ color: "blue", fontSize: "20px", fontWeight: "bolder", margin: "20px", marginTop: "auto", marginBottom: "auto" }}>Join Bible Study</div></div></Link>
</div>
            </div>
        );
    }
}



