import React from "react";
import { Link } from "react-router-dom";

export default class Fund extends React.Component {
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <div className="m-b-t">Fundraising for NGOs.</div>
                <Link to="/donate">  <button className="don">Donate</button></Link>
            </div>
        );
    }
}



